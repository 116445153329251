::selection {
  background-color: #3a96ed;
  color: white;
}

body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/Ubuntu-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 700;
  src: url('./assets/fonts/Ubuntu-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Ubuntu-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 200;
  src: url('./assets/fonts/Ubuntu-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 200;
  src: url('./assets/fonts/Ubuntu-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/Ubuntu-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 500;
  src: url('./assets/fonts/Ubuntu-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/Ubuntu-Regular.ttf') format('truetype');
}
